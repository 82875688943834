import React from "react"

import Layout from "../../components/layout/layout"
import Seo from "../../components/seo"
import Submenutreneri from "../../components/submenutreneri/submenutreneri"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'

import "./../treneri.scss"

import ImgTreneriSevcik from "../../images/treneri/jiri-sevcik.jpg"

const JiriSevcik = () => (

	<div>

		<Layout>
			<Seo title="Jiří Ševčík" description="Jiří Ševčík" />

			<div className="stk-container stk-container--top-margin">

				<Row>
					<Col xs="12" xl="9">
						<div class="stk-trener__header">
							<div>
								<img class="stk-trener__image" src={ImgTreneriSevcik} alt="Jiří Ševčík" />
							</div>
							<div>
								<h1 className="stk-h1">Jiří Ševčík</h1>
								<p>Trenér a porotce 1. třídy - standardní a latinsko-americké tance</p>
								<a className="stk-trener__social-icon stk-socialicon stk-socialicon--facebook" href="https://www.facebook.com/jirisevcik008" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faFacebookF} />
								</a>
								<a className="stk-trener__social-icon stk-socialicon stk-socialicon--phone" href="tel:+420777027703" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faPhone} />
								</a>
								<a className="stk-trener__social-icon stk-socialicon stk-socialicon--email" href="mailto:info@bohemiadance.cz" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faEnvelope} />
								</a>
							</div>
						</div>
						<ul>
							<li>Trenér a porotce I. třídy</li>
							<li>TOP porotce s&nbsp;mezinárodní licencí WDSF</li>
							<li>Absolvent Konzervatoře obor scénický tanec a&nbsp;choreografie</li>
							<li>Absolvent rekvalifikačního stiudiua pro&nbsp;porotu I.&nbsp;třídy ČSTS při&nbsp;ČASPV</li>
							<li>Člen skupiny výrazového tance a&nbsp;scénického tance Jiřího Rebce</li>
							<li>Lektor ČSTS</li>
							<li>Choreograf a&nbsp;choreografie pro&nbsp;ČT pořady a&nbsp;filmy: Pražský Montmartre, Svatba upírů, Děti noci</li>
							<li>Taneční mistr</li>
							<li>Pořadatel mezinárodní taneční soutěže WDSF Prague Open</li>
							<li>Pořadatel titulárních tanečních soutěží Mistrovství České republiky ve&nbsp;standardních a&nbsp;latinskoamerických tancích</li>
						</ul>
					</Col>
					<Col xs="12" xl="3">
						<Submenutreneri></Submenutreneri>
					</Col>
				</Row>
			</div>
		</Layout>

	</div>

)

export default JiriSevcik